<template>
  <div class="x-menu-bar">
    <div class="brand">
      <img
        :src="log"
        class="logo"
        mode="scaleToFill"
      />
    </div>

    <el-menu
      class="x-menu"
      text-color="#fff"
      active-text-color="#fff"
      background-color="#2349a4"
      unique-opened
      router
      :default-active="currentMenu"
      @select="setMenu"
    >
      <el-menu-item index="/user">
        <svg-icon iconClass="user" />
        <span slot="title">用户管理</span>
      </el-menu-item>
      <el-menu-item index="/notice">
        <svg-icon iconClass="notice" />
        <span slot="title">动态管理</span>
      </el-menu-item>
      <el-menu-item index="/brand">
        <svg-icon iconClass="pinpai" />
        <span slot="title">品牌管理</span>
      </el-menu-item>
      <el-menu-item index="/collection">
        <svg-icon iconClass="zangpinji" />
        <span slot="title">藏品集管理</span>
      </el-menu-item>
      <el-submenu index="#1" class="x-submenu">
        <template slot="title">
          <svg-icon iconClass="cangpin" />
          <span>藏品管理</span>
        </template>
        <el-menu-item index="/nft">藏品发行</el-menu-item>
        <el-menu-item index="/nftpass">藏品列表</el-menu-item>
        <!-- <el-menu-item index="/blindbox">盲盒发行</el-menu-item> -->
      </el-submenu>

      <el-submenu index="#2" class="x-submenu">
        <template slot="title">
          <svg-icon iconClass="shichang" />
          <span slot="title">市场管理</span>
        </template>
        <el-menu-item index="/market">藏品上架</el-menu-item>
        <!-- <el-menu-item index="/blindboxmarket">盲盒上架</el-menu-item> -->
        <el-menu-item index="/nftmarket">藏品市场</el-menu-item>
      </el-submenu>

      <el-submenu index="#3" class="x-submenu">
        <template slot="title">
          <svg-icon iconClass="shenhe" />
          <span>审核管理</span>
        </template>
        <el-menu-item index="/audit/collection">藏品集审核</el-menu-item>
        <el-menu-item index="/audit/nft">藏品审核</el-menu-item>
        <el-menu-item index="/audit/market">上架审核</el-menu-item>
      </el-submenu>
      <el-menu-item index="/compose">
        <svg-icon iconClass="compose" />
        <span slot="title">合成管理</span>
      </el-menu-item>
      <el-menu-item index="/airdrop">
        <svg-icon iconClass="airdrop" />
        <span slot="title">空投管理</span>
      </el-menu-item>
      <el-submenu index="#4" class="x-submenu">
        <template slot="title">
          <svg-icon iconClass="dingdan" />
          <span>订单管理</span>
        </template>
        <el-menu-item index="/transaction">交易记录</el-menu-item>
        <el-menu-item index="/order">购买订单</el-menu-item>
      </el-submenu>
      <el-submenu index="#5" class="x-submenu">  
        <template slot="title">
          <svg-icon iconClass="dingdan" />
          <span>钱包管理</span>
        </template>
        <el-menu-item index="/account">用户账户</el-menu-item>
        <el-menu-item index="/cashout">提现审批</el-menu-item>
        <el-menu-item index="/paylog">支付记录</el-menu-item>
      </el-submenu>
      <el-menu-item index="/app">
        <svg-icon iconClass="app" />
        <span slot="title">App管理</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>


<script>
import logImg from './../../assets/img/logo_a.png'
export default {
  name: "Menu",
  data() {
    return {
      log:logImg
    }
  },
  computed: {
    currentMenu() {
      return this.$store.state.currentMenu;
    },
  },
  methods: {
    setMenu(index, indexPath) {
      //console.log(index, indexPath);
      this.$store.dispatch("setMenu", index);
    },
  },
};
</script>

<style lang="less">
.x-menu-bar {
  height: 100vh;
  background-color: #2349a4;
  overflow-x: hidden;
}
.x-menu-bar::-webkit-scrollbar {
  width: 1px;
}
.x-menu-bar::-webkit-scrollbar-thumb {
  border-radius:  10px;
  box-shadow: inset 0 0 5px rgba(35, 73, 164);
  background: rgba(35, 73, 164);
}
.x-menu-bar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(35, 73, 164);
  border-radius: 0;
  background: rgba(35, 73, 164);
}

.brand {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 16px 0 16px;
  height: 88px;
  color: #fff;
  font-size: 18px;

  .logo {
    width: 120px;
    height: 56px;
  }

  span {
    letter-spacing: 1px;
    font-weight: 600;
  }
}

// 一级菜单
.x-menu {
  height: 100%;
  // height: 100vh;
  border-right: none;
  z-index: 9;

  // 一级
  & > .el-menu-item {
    padding-right: 30px;
    height: 66px;
    line-height: 66px;
    text-indent: 20px;
    font-size: 16px;
  }

  .el-menu-item.is-active::before {
    content: "";
    z-index: 3;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 5px;
    height: 100%;
    background: linear-gradient(180deg, #2ebeff 0%, #3e96fe 100%);
  }
  .el-menu-item.is-active::after {
    content: "";
    z-index: 2;
    position: absolute;
    left: 5px;
    right: 0;
    top: 0;
    bottom: 0;
    width: calc(100% - 5px);
    height: 100%;
    background-color: #649cee;
    opacity: 0.2;
  }
}

// 二级菜单
.el-submenu {
  .el-submenu__title {
    padding-right: 30px;
    text-indent: 20px;
    height: 66px;
    line-height: 66px;
    font-size: 16px;
  }
  .el-menu-item {
    text-indent: 50px;
    font-size: 14px;
  }

  .el-submenu__icon-arrow {
    display: none;
    text-indent: 0;
    font-weight: bold;
    right: 30px;
    font-size: 16px;
    color: #fff;
  }
}
.svg-icon {
  color: #fff;
}
</style>

